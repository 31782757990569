import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Input from '../Components/Input';
import { loginUser, logoutUser, registerUser } from '../Redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Axios from '../Axios';
import axios from 'axios';
import { toast } from 'react-toastify';

import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import Search from '../Components/Search';
// import GetStaticValue from '../utility/GetStaticValue';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { jwtDecode } from 'jwt-decode';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Pusher from 'pusher-js';
import "../Style.css";
import { addNoti } from '../Redux/notificationSlice';
import { updateCommons } from '../Redux/commonSlice';
import { updateLoader } from '../Redux/loaderSlice';
import Logo from "../icon/logo.png";
import GoogleLogo from "../icon/googleicon.png";
import GetStaticValue from '../utility/GetStaticValue';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
// import GoogleLoginButton from '../Components/GoogleLoginButton';
const Header = () => {

  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);
  const [phone, setPhone] = useState('');

  const [sidebar, setSidebar] = useState(false)

  const dispatch = useDispatch();
  const authenticatedid = useSelector((state) => state.user.id);
  const notificationData = useSelector((state) => state.notification);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation()
  const navigate = useNavigate()
  const [loginshow, setLoginShow] = useState(false);
  const loginshow1 = useSelector(state => state.common);
  const loader = useSelector(state => state.loader);
  const [registrationshow, setRegistrationShow] = useState(false);
  const [vendorRegistrationshow, setVendorRegistrationShow] = useState(false);
  const [forgotpassword, setForgotpassword] = useState(false);
  const isAuthenticated = useSelector((state) => state.user.token);
  const errorMessage = useSelector(state => state.user.errorMessage);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const handleRegidtrationClose = () => {
    setRegistrationShow(false); setErrorData(initialErrorState);
    handleFormReset();
  }
  const handleRegidtrationShow = () => {
    handleIncreaseWidth1();
    setRegistrationShow(true); setErrorData(initialErrorState);
  }
  const handleLoginClose = () => {
    handleFormReset();
    setLoginShow(false)
    setErrorData(initialErrorState);
    dispatch(updateCommons({ loginshow: false }));
  };
  const isPhoneValid = (phone) => {
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
      console.log(phoneNumber, "phonenumber")
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  };

  const handleLoginShow = () => {
    setErrorData(initialErrorState);
    dispatch(updateCommons({ loginshow: true }));

    handleFormReset();
    setLoginShow(true);
    handleIncreaseWidth1();
  };

  const handleForgotClose = () => {
    setForgotpassword(false); setErrorData(initialErrorState);
  }
  const handleForgotShow = () => { setForgotpassword(true); setErrorData(initialErrorState); setRegistrationShow(false); handleLoginClose(false) };

  const initialState = {
    name: "",
    email: "",
    phone: "",
    password: "",

    confirm_password: "",
  };

  const initialErrorState = {
    nameError: "",
    emailError: "",
    phoneError: "",
    passwordError: "",
    confirm_passwordError: "",
  };
  const [values, setValues] = useState('')
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  // const handleEditChange = (value, s, field) => {
  //   const country_short_code = s.country.iso2.toUpperCase();
  //   const dialCode = s.country?.dialCode || '';
  //   let rawInputValue = (s.inputValue || '').toString(); // Ensure inputValue is a string

  //   // Extract numbers from rawInputValue
  //   rawInputValue = rawInputValue.match(/\d+/g);
  //   rawInputValue = rawInputValue ? rawInputValue.join('') : '';

  //   const newValues = {
  //     ...value,
  //     'phone': value,
  //     'dialCode': dialCode,
  //     'rawInputValue': rawInputValue,
  //     'country_short_code': country_short_code,
  //   };

  //   setValues(newValues);

  //   // Validate phone number and determine save button status
  //   // const isValid = isPhoneValid(value);
  //   // setIsPhoneValidState(isValid);
  //   // setErrors({ ...errors, phone: isValid ? '' : 'Invalid phone number' });

  //   // Check if the new phone value differs from the original edited value
  //   // const isPhoneChanged = value !== editedValues.phone;

  //   // Disable save if phone is invalid or if phone hasn't changed
  //   // const disableSave = !isValid || !isPhoneChanged;
  //   // setIsSaveDisabled(!disableSave);
  // };
  const closeHeader = () => {
    setIsHeaderOpen(false);
  };
  const handleEditChange = (value, s, field) => {
    const phone = value;
    const country_short_code = s?.country?.iso2.toUpperCase();
    const country_code = s?.country?.dialCode;
    let rawInputValue = (s.inputValue || '').toString();
    rawInputValue = rawInputValue.match(/\d+/g);
    rawInputValue = rawInputValue ? rawInputValue.join('') : '';
    const newValues = {
      ...values,
      'phone1': phone,
      'phone': rawInputValue,
      'rawInputValue': rawInputValue,
      'country_code': country_code,
      'country_short_code': country_short_code,

    };
    setFormData(prevState => ({
      ...prevState,
      country_code: newValues?.country_code,
      phone: newValues?.rawInputValue,
      country_short_code: newValues?.country_short_code,
      phone1: newValues?.phone1,

    }));

  };

  const [formData, setFormData] = useState({
    ...initialState,
    country_code: '',
    phone: '',
    country_short_code: '',
    document_type: 'PAN',
    document: null,

  });

  const [errorData, setErrorData] = useState(initialErrorState);

  useEffect(() => {
    setErrorData(prevState => ({ ...prevState, passwordError: errorMessage }));
  }, [errorMessage])



  const handleFormReset = () => {
    setFormData(initialState); // Reset form data to initial state
  };

  const handleErrorReset = () => {
    setErrorData(initialErrorState); // Reset form data to initial state
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    let filteredValue = value;
    if (type === "tel") {
      // If the input type is phone, you may want to update the state accordingly
      filteredValue = value.replace(/\D/g, '');
      setFormData(prevState => ({
        ...prevState,
        [name]: filteredValue
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


  const handleLogin = async (e) => {

    try {

      handleErrorReset();
      if (e.email.length < 2) {
        setErrorData(prevState => ({ ...prevState, emailError: "Please enter current user email", }));
      } else if (e.password.length < 2) {
        setErrorData(prevState => ({ ...prevState, passwordError: "Invalid Email or Password" }));

      } else {

        const resp = await dispatch(loginUser({ 'email': e.email, 'password': e.password }));
        console.log(resp)
        if (resp.type == "auth/loginUser/rejected") {
          setErrorData(prevState => ({ ...prevState, passwordError: "Invalid Email or Password " }));

        } else if (resp.type == "auth/loginUser/fulfilled") {
          handleLoginClose();
          toast.success("Login successful!")
        }

      }
    } catch (error) {
      console.log(error)
      setErrorData(prevState => ({ ...prevState, passwordError: "Invalid Email or Password" }));

    }
  };
  const [image, setImage] = useState(localStorage.getItem('image'));
  useEffect(() => {
    const handleImageUpdated = (event) => {
      setImage(event.detail);
    };

    window.addEventListener('imageUpdated', handleImageUpdated);

    return () => {
      window.removeEventListener('imageUpdated', handleImageUpdated);
    };
  }, []);
  useEffect(() => {
    console.log(`Navigated to ${location.pathname}`);
  }, [location]);
  const handleSignUp = async (e) => {
    try {
      handleErrorReset();

      if (e.name.length < 2) {
        setErrorData(prevState => ({ ...prevState, nameError: "Please enter minimum 2 character", }));
      } else if (e.email.length < 2) {
        setErrorData(prevState => ({ ...prevState, emailError: "Please enter correct email" }));
        // } else if (e.phone.length < 12) {
        //   setErrorData(prevState => ({ ...prevState, phoneError: "Please enter minimum 10 digit phone number" }));
      } else if (e.password.length < 2) {
        setErrorData(prevState => ({ ...prevState, passwordError: "Please enter minimum 8 digit password" }));
      } else if (e.confirm_password.length < 2) {
        setErrorData(prevState => ({ ...prevState, confirm_passwordError: "Please enter minimum 8 digit password" }));
      } else if (e.password !== e.confirm_password) {
        setErrorData(prevState => ({ ...prevState, confirm_passwordError: "Please enter confirm password same as password" }));
        // } else if (!isPhoneValid(e.phone)) {
        //   setErrorData(prevState => ({ ...prevState, phoneError: "Please enter a valid phone number" }));
      } else {
        const resp = await axios.post(process.env.REACT_APP_API_URL + "/register", e);
        console.log(resp)
        if (resp.data.code == 'EC200') {
          dispatch(updateLoader({ loader: true }));
          dispatch(loginUser({ 'email': e.email, 'password': e.password }));
          handleFormReset()
          handleRegidtrationClose();
          toast.success("User created successfully")
          dispatch(updateLoader({ loader: false }));
        }

      }
    } catch (error) {
      // Handle error response
      console.error('Error:', error);
      console.error('Status code:', error.response.status);
      if (error.response.status === 404) {

      } else if (error.response.data.code === "EC400") {
        Object.keys(error.response.data.data.Validation_Error).map(field => (
          setErrorData(prevState => ({ ...prevState, [field + 'Error']: error.response.data.data.Validation_Error[field] }))
        ))

      } else if (error.response.status === 500) {
        // Handle 500 error
      } else {
        // Handle other errors
      }
    }
  };

  const handleForgotPassowrd = async (e) => {
    try {
      handleErrorReset();
      if (e.email.length < 2) {
        setErrorData(prevState => ({ ...prevState, emailError: "Please enter correct email" }));
      } else {
        dispatch(updateLoader({ loader: true }));
        const resp = await axios.post(process.env.REACT_APP_API_URL + "/forgot-password", e);
        console.log(resp)
        if (resp.data.code == 'EC200')
          handleFormReset()
        handleForgotClose();
        toast(resp.data.data.message)
        dispatch(updateLoader({ loader: false }));
      }

    }
    catch (error) {
      // Handle error response
      dispatch(updateLoader({ loader: false }));

      console.error('Error:', error);
      console.error('Status code:', error.response.status);
      if (error.response.status === 404) {

      } else if (error.response.status === 400) {
        Object.keys(error.response.data.data.Validation_Error).map(field => (
          setErrorData(prevState => ({ ...prevState, [field + 'Error']: error.response.data.data.Validation_Error[field] }))
        ))
      } else if (error.response.status === 500) {
        // Handle 500 error
      } else {
        // Handle other errors
      }
      dispatch(updateLoader({ loader: false }));

    }
  }

  const logout = async () => {
    try {

      await dispatch(logoutUser());
      const response = await Axios.post("/logout");
      if (response.status === 200) {
        dispatch(updateLoader({ loader: true }));
        localStorage.removeItem('name')
        localStorage.removeItem('token')
        localStorage.removeItem('image')
        dispatch(updateLoader({ loader: false }));
      } else {
        dispatch(updateLoader({ loader: true }));
        console.log('show error')
        dispatch(updateLoader({ loader: false }));
      }
      handleIncreaseWidth1();
    } catch (error) {
      console.error('Error:', error);
      console.error('Status code:', error.response.status);
      handleIncreaseWidth1();
      if (error.response.status === 404) {
        console.log('404')
      } else if (error.response.status === 500) {
        console.log('500')
      } else if (error.response.status === 401) {
        console.log('401')
      } else {
        console.log('else')
      }
    }
  }
  const handleGoogle = async () => {
    try {

      const response = await axios.get("http://127.0.0.1:8000/api/login/google");
      if (response.status === 200) {
        // localStorage.removeItem('name')
        // localStorage.removeItem('token')
        // localStorage.removeItem('image')
      } else {
        console.log('show error')
      }
    } catch (error) {
      console.error('Error:', error);
      // console.error('Status code:', error.response.status);
      // if (error.response.status === 404) {
      //   console.log('404')
      // } else if (error.response.status === 500) {
      //   console.log('500')
      // } else if (error.response.status === 401) {
      //   console.log('401')
      // } else {
      //   console.log('else')
      // }
    }
  }



  const [width, setWidth] = useState(0); // Initial width
  const handleIncreaseWidth = () => {
    if (width == 0) {
      setWidth(350);
    } else {
      setWidth(0);
    }
    // Update the width to increase by 100 pixels

  };
  const handleIncreaseWidth1 = () => {
    if (width == 350) {
      setWidth(0);
    }
    // Update the width to increase by 100 pixels

  };


  const fetchData = async () => {
    try {
      const resp = await Axios.get(`/notification-count`);
      if (resp.data.success && resp.data.code === "EC200") {
        const notificationData = resp.data.data?.data || 0;
        dispatch(addNoti(notificationData));
      } else {
        dispatch(addNoti(0));
      }
    } catch (error) {
      dispatch(addNoti(0));
      console.error('Error fetching notification data:', error);
    }
  };

  useEffect(() => {
    // Initialize Pusher only once
    const pusher = new Pusher('f421f16e68d5f31688d1', {
      cluster: 'ap2',
      encrypted: true
    });

    const channel = pusher.subscribe('user_channel_' + authenticatedid);
    channel.bind('notification', (data) => {
      fetchData();
      enqueueSnackbar('A new notification received', { variant: 'success' });
    });

    fetchData(); // Initial fetch

    return () => {
      pusher.disconnect();
    };
  }, [authenticatedid]);


  const [loginUrl, setLoginUrl] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/api/auth', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong!');
      })
      .then((data) =>
        setLoginUrl(data.url)
      )
      .catch((error) => console.error(error));
  }, []);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > (elTopOffset + elHeight)) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height)
    }

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  const handlePhoneChange = (phone) => {
    const filteredPhone = phone.replace(/\D/g, '');
    setFormData((prevState) => ({
      ...prevState,
      phone: filteredPhone,
    }));
  };






  const inputs = document.querySelectorAll('.uplodad_document_file');

  for (let input of inputs) {
    const label = input.nextElementSibling;
    const labelVal = label.innerHTML;

    input.addEventListener('change', (e) => {
      let fileName = '';
      if (input.files && input.files.length > 1) {
        fileName = (input.getAttribute('data-multiple-caption') || '').replace('{count}', input.files.length);
      } else {
        fileName = e.target.value.split('\\').pop();
      }

      if (fileName) {
        label.querySelector('span').innerHTML = fileName;
      } else {
        label.innerHTML = labelVal;
      }
    })
  }




  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (formData.name === '') {
      errors.nameError = 'Name is required';
      isValid = false;
    }
    if (formData.email === '') {
      errors.emailError = 'Email is required';
      isValid = false;
    }
    if (!isPhoneValid(formData.phone1)) {
      errors.phoneError = 'Valid phone number is required';
      isValid = false;
    }
    if (formData.document_type === '') {
      errors.documentError = 'Document type is required';
      isValid = false;
    }
    if (!formData.document) {
      errors.fileError = 'Document file is required';
      isValid = false;
    }

    setErrorData(errors);
    return isValid;
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      document: file,
    }));
  };
  const handleSignUpVendor = async (formData) => {
    function removeCountryCode(phone, countryCode) {
      if (phone.startsWith(countryCode)) {
        return phone.slice(countryCode.length);
      }
      return phone;
    }
    const data = new FormData();
    const phoneNumber = formData.phone;
    const modifiedPhoneNumber = removeCountryCode(phoneNumber, formData.country_code || '');
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phoneoriginal', formData.phone)
    data.append('phone', modifiedPhoneNumber);
    data.append('country_code', formData?.country_code)
    data.append('country_short_code', formData?.country_short_code)
    data.append('document_type', formData.document_type);
    data.append('document', formData.document);
    data.append('password', formData.password);
    data.append('confirm_password', formData.confirm_password);
    console.log(formData, "formdata")
    if (!validateForm()) return;
    try {
      dispatch(updateLoader({ loader: true }));
      const response = await axios.post(process.env.REACT_APP_API_URL + '/admin/register', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(`${response.data.data.message}`)
      setVendorRegistrationShow(false)
      setFormData(initialState);
      dispatch(updateLoader({ loader: false }));
      // Handle successful response
    } catch (error) {
      // Handle error response
      dispatch(updateLoader({ loader: false }));
      console.error('Error:', error);
      toast.error(error.response.data.data.message);
      console.error('Status code:', error.response.status);
      if (error.response.status === 404) {

      } else if (error.response.data.code === "EC400") {
        Object.keys(error.response.data.data.Validation_Error).map(field => (
          setErrorData(prevState => ({ ...prevState, [field + 'Error']: error.response.data.data.Validation_Error[field] }))
        ))

      } else if (error.response.status === 500) {
        // Handle 500 error
        dispatch(updateLoader({ loader: false }));
      } else {
        // Handle other errors
        dispatch(updateLoader({ loader: false }));
      }
      dispatch(updateLoader({ loader: false }));
    }
  };



  return (
    <>


      <Modal show={loginshow1.loginshow} onHide={handleLoginClose}>
        <Modal.Body>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="">
              <div className="AppForm rental_property_login_form_wrapper">
                <div className="AppFormLeft">
                  <div className="rentel_login_logo">
                    {/* <img src={Logo} alt="logo" /> */}
                    <img src="/images/logo.png" className="img-fluid" alt="logo" />
                  </div>
                  <div className="rentel_login_header">
                    <h1>Login</h1>
                  </div>
                  <Input
                    title={'Email Address'}
                    type={'email'}
                    name={'email'}
                    icon={'/icon/Vector.svg'}
                    placeholder={'Please enter Email'}
                    value={formData.email}
                    onChange={handleChange}
                    error={errorData.emailError} />

                  <Input
                    title={'Password'}
                    type={'password'}
                    name={'password'}
                    icon={'/icon/Group 20.svg'}
                    placeholder={'Please enter Password'}
                    value={formData.password}
                    onChange={handleChange}

                    error={errorData.passwordError} />

                  <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                      <div className="forgot_password_text">
                        <Link style={{ cursor: 'pointer', color: 'var(--textcolor)', }} onClick={() => handleForgotShow()}>Forgot Password?</Link>
                      </div>
                    </div>
                  </div>
                  <button className="button" style={(formData.email === '' || formData.password === '')
                    ? styles.disabledButton : styles.enabledButton}
                    disabled={(formData.email === '' || formData.password === '')} onClick={() => handleLogin(formData)}>Log in</button>
                  <div className="line_border_spece">
                    <hr className="sep-3" />
                  </div>
                  <div className="social_wrapper_login">
                    <div className="icon-wrapper">
                      <div className=''>
                        {loginUrl != null && (
                          <Link to={loginUrl}><img src={GoogleLogo} /></Link>
                        )}
                      </div>

                    </div>
                  </div>
                  <div className="bottom_dont_account">
                    Don't have an account? <Link style={{ color: '#006AA0', cursor: 'pointer' }} onClick={() => { handleLoginClose(); handleRegidtrationShow() }}> Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

      <Modal show={registrationshow} onHide={handleRegidtrationClose}>
        <Modal.Body>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="">
              <div className="AppForm rental_property_login_form_wrapper">
                <div className="AppFormLeft">
                  <div className="rentel_login_logo">
                    {/* <img src={Logo} alt="logo" /> */}
                    <img src="/images/logo.png" className="img-fluid" alt="logo" />
                  </div>
                  <div className="rentel_login_header">
                    <h1>Sign Up</h1>
                  </div>

                  <Input
                    title={'Name'}
                    type={'text'}
                    name={'name'}
                    icon={'/icon/geust.png'}
                    placeholder={'Please enter Name'}
                    value={formData.name}
                    onChange={handleChange}
                    error={errorData.nameError} />

                  <Input
                    title={'Email Address'}
                    type={'email'}
                    name={'email'}
                    icon={'/icon/Vector.svg'}
                    placeholder={'Please enter Email'}
                    value={formData.email}
                    onChange={handleChange}
                    error={errorData.emailError} />
                  <div className="title_profile" style={{ marginBottom: '1%', }}>Phone</div>
                  <PhoneInput
                    defaultCountry="fr"
                    value={formData.phone}
                    onChange={(value, s) => {
                      handleEditChange(value, s, 'phone');
                      console.log(value, s);
                      console.log(formData.phone)
                      // handleEditChange(value, 'phone')
                    }}
                  />

                  {errorData.phoneError && <div className="error_input">{errorData.phoneError}</div>}

                  <Input
                    title={'Password'}
                    type={'password'}
                    name={'password'}
                    icon={'/icon/Group 20.svg'}
                    placeholder={'Please enter Password'}
                    value={formData.password}
                    onChange={handleChange}
                    error={errorData.passwordError}
                    maxLength={''} />

                  <Input
                    title={'Confirm Password'}
                    type={'password'}
                    name={'confirm_password'}
                    icon={'/icon/Group 20.svg'}
                    placeholder={'Please enter Password'}
                    value={formData.confirm_password}
                    onChange={handleChange}
                    error={errorData.confirm_passwordError}
                  />


                  <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                      <div className="forgot_password_text">
                        <Link style={{ cursor: 'pointer', color: 'var(--textcolor)', }} onClick={() => handleForgotShow()}>Forgot Password?</Link>
                      </div>
                    </div>
                  </div>
                  <button
                    className="button"
                    style={formData.name === '' || formData.email === '' || isPhoneValid(formData.phone) || formData.password === '' || formData.confirm_password === ''
                      ? styles.disabledButton : styles.enabledButton}
                    disabled={formData.name === '' || formData.email === '' || isPhoneValid(formData.phone) || formData.password === '' || formData.confirm_password === ''}
                    onClick={() => handleSignUp(formData)}
                  >
                    Sign Up
                  </button>
                  <div className="line_border_spece">
                    <hr className="sep-3" />
                  </div>
                  <div className="social_wrapper_login">
                    <div className="icon-wrapper">
                      {loginUrl != null && (
                        <Link to={loginUrl}><img src={GoogleLogo} /></Link>
                      )}
                    </div>
                  </div>
                  <div className="bottom_dont_account">
                    Don't have an account? <Link style={{ color: 'var(--textcolor)', cursor: 'pointer' }} onClick={() => { handleRegidtrationClose(); handleLoginShow() }}> Sign In</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >





      <Modal show={vendorRegistrationshow} onHide={() => setVendorRegistrationShow(false)}>
        <Modal.Body>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="">
              <div className="AppForm rental_property_login_form_wrapper">
                <div className="AppFormLeft">
                  <div className="rentel_login_logo">
                    <img src="/images/logo.png" className="img-fluid" alt="logo" />
                  </div>
                  <div className="rentel_login_header">
                    <h1>Vendor Registration</h1>
                  </div>

                  <Input
                    title="Name"
                    type="text"
                    name="name"
                    icon={'/icon/geust.png'}
                    placeholder="Please enter Name"
                    value={formData.name}
                    onChange={handleChange}
                    error={errorData.nameError}
                  />

                  <Input
                    title="Email Address"
                    type="email"
                    name="email"
                    icon={'/icon/Vector.svg'}
                    placeholder="Please enter Email"
                    value={formData.email}
                    onChange={handleChange}
                    error={errorData.emailError}
                  />

                  <div className="title_profile" style={{ marginBottom: '1%' }}>Phone</div>
                  <PhoneInput
                    defaultCountry="fr"
                    value={formData.phone}
                    onChange={(value, s) => {
                      handleEditChange(value, s, 'phone');
                      console.log(value, s);
                      console.log(formData.phone)
                      // handleEditChange(value, 'phone')
                    }}
                  />
                  {errorData.phoneError && <div className="error_input">{errorData.phoneError}</div>}
                  <div className="doucement_wrapper">
                    <label className="form-check-label logiform_lable">Document Type</label>
                    <div className="input_field_document_wrapper">
                      <div className="doucement_valuse_box">
                        <input
                          type="radio"
                          className="radio_box_document"
                          name="document_type"
                          id="rd1"
                          value="PAN"
                          checked={formData.document_type === 'PAN'}
                          onChange={handleChange}
                        />
                        <label htmlFor="rd1" className="document_data">PAN</label>
                      </div>

                      <div className="doucement_valuse_box">
                        <input
                          type="radio"
                          className="radio_box_document"
                          name="document_type"
                          id="rd2"
                          value="Adhaar"
                          checked={formData.document_type === 'Adhaar'}
                          onChange={handleChange}
                        />
                        <label htmlFor="rd2" className="document_data">Adhaar</label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-check-label logiform_lable">Upload Document</label>
                    <div className="uploade_file_notifaction">Only JPEG,PDF,DOC  are accepted</div>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      accept=".jpg,.jpeg,.doc,.docx,.pdf"
                      className="uplodad_document_file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="file"><span>Upload Documents</span></label>
                  </div>
                  <Input
                    title={'Password'}
                    type={'password'}
                    name={'password'}
                    icon={'/icon/Group 20.svg'}
                    placeholder={'Please enter Password'}
                    value={formData.password}
                    onChange={handleChange}
                    error={errorData.passwordError}
                  />

                  <Input
                    title={'Confirm Password'}
                    type={'password'}
                    name={'confirm_password'}
                    icon={'/icon/Group 20.svg'}
                    placeholder={'Please enter Password'}
                    value={formData.confirm_password}
                    onChange={handleChange}
                    error={errorData.confirm_passwordError}
                  />
                  <button
                    className="button vendorbtn"
                    style={
                      formData.name === '' ||
                        formData.email === '' ||
                        formData.document_type === '' ||
                        !formData.document || formData.password === '' || formData.password === ''
                        ? { opacity: 0.5, cursor: 'not-allowed' }
                        : { opacity: 1, cursor: 'pointer' }
                    }
                    disabled={
                      formData.name === '' ||
                      formData.email === '' ||
                      formData.document_type === '' ||
                      !formData.document || formData.password === '' || formData.password === ''
                    }
                    onClick={() => handleSignUpVendor(formData)}
                  >
                    Register
                  </button>
                  <div className="bottom_dont_account">You are already registered <a href="https://propertyrental.dev.subtlelabs.com/admin/login">Login</a></div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <Modal show={forgotpassword} onHide={handleForgotClose}>
        <Modal.Body>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="">
              <div className="AppForm rental_property_login_form_wrapper">
                <div className="AppFormLeft">
                  <div className="rentel_login_logo">
                    {/* <img src={Logo} alt="logo" /> */}
                    <img src="/images/logo.png" className="img-fluid" alt="logo" />
                  </div>
                  <div className="rentel_login_header">
                    <h1>Forgot Password</h1>
                  </div>
                  <Input
                    title={'Email Address'}
                    type={'email'}
                    name={'email'}
                    icon={'/icon/Vector.svg'}
                    placeholder={'Please enter Email'}
                    value={formData.email}
                    onChange={handleChange}
                    error={errorData.emailError} />

                  <button className="button" style={formData.email === ''
                    ? styles.disabledButton : styles.enabledButton}
                    disabled={formData.email === ''} onClick={() => handleForgotPassowrd(formData)}>Send Link</button>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <nav ref={headerRef}>
        <div className={`header_section ${sticky.offset ? 'stycky_header1' : ''}`}  >
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg">
              <Link to="/" className="navbar-brand property_rentel_logo">
                <img src="/images/newlogo.svg" className="img-fluid" alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleIncreaseWidth}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                {location.pathname !== '/product-filter' ? (
                  <ul className="navbar-nav m-auto mb-2 mb-lg-0 property_rentel_nav">
                    <li className="nav-item">
                      <Link to="/about-us" className="nav-link" aria-current="page" href="#">
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/help" className="nav-link" aria-current="page" href="#">
                        Help
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/privacypolicy" className="nav-link" aria-current="page" href="#">
                        Privacy Policy
                      </Link>
                    </li>

                    {/* {!isAuthenticated && <li className="nav-item">
                      <Link className="nav-link" onClick={() => setVendorRegistrationShow(!vendorRegistrationshow)} >
                        Vendor register
                      </Link>
                    </li>} */}
                  </ul>

                ) : (
                  <Search />
                )}

                {!isAuthenticated && <div className="nav-item ">
                  <div className="btn verdorbtn" onClick={() => setVendorRegistrationShow(!vendorRegistrationshow)} >
                    Vendor
                  </div>
                </div>}

                {!isAuthenticated || isAuthenticated === null ? (
                  <Dropdown>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className="border-0">
                      <img src="/icon/usericon.png" alt="logo" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='afterlogin_dropdown'>
                      <Dropdown.Item onClick={handleLoginShow}>Login</Dropdown.Item>
                      <Dropdown.Item onClick={handleRegidtrationShow}>Sign Up</Dropdown.Item>
                      <Dropdown.Divider className='divider_border' />
                      <Dropdown.Item eventKey="4"><Link to="/help" style={{ color: 'black', textDecorationLine: 'none' }}>Help center</Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className="border-0">
                      <div className='before_login_header'>
                        <div className='before_login_title'>
                          {localStorage.getItem("name")}
                        </div>
                        <div>
                          {notificationData > 0 && (
                            <div className='blinking-dot' style={{ animation: 'none' }}></div>
                          )}
                          {notificationData > 0 ? (
                            <div className='notification-count'>{notificationData}</div>
                          ) : null}
                          <Avatar src={localStorage.getItem("image")} className='afterlogin_avtore'></Avatar>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='beforelogin_dropdown'>
                      <Dropdown.Item><Link to={"/user/profile"} >Profile</Link></Dropdown.Item>
                      <Dropdown.Item><Link to={"/user/notification"} >Notifications </Link> </Dropdown.Item>
                      <Dropdown.Item><Link to={"/user/trips"} >Trips </Link> </Dropdown.Item>
                      <Dropdown.Item><Link to={"/user/wishlists"} >Wishlists </Link> </Dropdown.Item>
                      <Dropdown.Item><Link to={"/user/change"} >Login & Security</Link></Dropdown.Item>
                      <Dropdown.Item><Link to={"/user/orders"} >Payment</Link></Dropdown.Item>

                      <Dropdown.Divider className='divider_border' />
                      <Dropdown.Item className='loginoutbtn' eventKey="4" onClick={() => logout()}>Log Out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </nav>
            <div className="loader-container" style={{ display: loader.loader ? 'block' : 'none', }}>
              <div className="loader_new" ></div>
            </div>
          </div>
        </div>
      </nav>


      {/* {!isAuthenticated || isAuthenticated === null ? (): ()} */}

      <div className='mobile_nav' >
        <div id="mySidenav" className="sidenav" style={{ width: `${width}px` }}>
          <Link className="closebtn" onClick={handleIncreaseWidth}>&times;</Link>
          <div className='user_profile'>
            <div className='mobileuserprofile_wrapper'>
              <div className='mobileuserprofile_inner_wrapper'>
                <div className='mobile_profile_picture'><Avatar src={localStorage.getItem("image")} sx={{ bgcolor: deepPurple[500] }}></Avatar></div>
                <div className='mobile_profile_textname'>{localStorage.getItem("name")}</div>
              </div>
            </div>
          </div>
          {!isAuthenticated || isAuthenticated === null ? (
            <div className='profile_nav'>
              <div className='mobile_menu_text'><Link onClick={handleLoginShow}>Login</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleRegidtrationShow}>Sign Up</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to="/help" eventKey="4">Help Center</Link></div>
            </div>
          ) : (
            <div className='profile_nav'>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to={"/user/profile"}>Profile</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to={"/user/notification"}>Notifications</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to={"/user/trips"}>Trips</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to={"/user/wishlists"}>Wishlists</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to={"/user/change"}>Login & Security</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to={"/user/orders"}>Payment</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to="/about-us">About Us</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to="/help">Help</Link></div>
              <div className='mobile_menu_text'><Link onClick={handleIncreaseWidth1} to="/privacypolicy">Privacy Policy</Link></div>
              <div className='mobile_menu_text'><Link onClick={() => logout()}>Log Out</Link></div>
            </div>
          )}



        </div>
      </div>











    </>
  )
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Header />
    </SnackbarProvider>
  );
}
const styles = {
  disabledButton: {
    backgroundColor: '#D8D8D8',
    color: 'white',
    cursor: 'not-allowed',

  },
  enabledButton: {
    background: 'var(--primary)',
    color: 'white',
    cursor: 'pointer',
  },

};
